require('bootstrap');

require('datatables.net-bs4');
require('viewerjs');

require('./custom/main');
require('./custom/mobile-navbar-expand');
require('./custom/multilevel-dropdown');
require('./custom/gtm');
require('./custom/disable-right-click');
